#experiences {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 2rem 0;
    margin-top: 0;
    margin-bottom:0;
}

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: var(--color-primary);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

.timeline-item {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
    box-shadow: 0 0 25px #8e8eff, 0 0 50px #c49cff8a;
    border-radius: 2rem;
    margin-bottom: 2rem;
    transition: var(--transition);
}

.timeline-item::after {
    content: ' ';
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: var(--color-bg);
    border: 4px solid var(--color-primary);
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

.timeline-item:nth-child(even) {
    left: 50%;
}

.timeline-item:nth-child(even)::after {
    left: -16px;
}

.timeline-item:nth-child(odd) {
    left: 0;
}

.timeline-item:nth-child(odd)::after {
    right: -16px;
}

.company_name {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
}

.experience-actions {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.experience-actions .btn {
    margin-right: 10px;
}

.show-details {
    background-color: var(--color-black);
    color: var(--color-bg);
    border: 1px solid transparent;
    border-radius: 0.4rem;
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    transition: var(--transition);
    font-weight: bold;
}

.show-details:hover {
    background: var(--color-primary) !important;
    color: var(--color-bg) !important;
}

.link-btn, .linkedin-btn {
    background-color: var(--color-bg);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    transition: var(--transition);
}

.link-btn:hover, .linkedin-btn:hover {
    background-color: var(--color-primary);
    color: var(--color-bg);
}

.date {
    text-align: left !important;
    color: var(--color-light);
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

h5 {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: var(--color-light);
}

h2 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 10rem;
    color: var(--color-primary);
}
