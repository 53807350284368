:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(255, 255, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --transition: all 600ms ease; /* Smoother transition */
  }

  body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    margin: 0;
    padding: 0;
    transition: var(--transition);
  }

  body.dark-mode {
    background: #0b0c10;
  }

  body.light-mode {
    background: #ffffff; /* White for light mode */
  }

  header {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-bg);
    overflow: hidden;
    position: relative;
  }

  header.dark {
    background: var(--color-bg);
  }

  header.light {
    background: #ffffff; /* White for light mode */
  }

  section#about {
    background: var(--color-bg); /* Matching the home page background */
    color: var(--color-white);
    padding: 4rem 0; /* Adjust margin */

  }

  .about__container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5%;
    align-items: center;
    padding-right: 3rem;
  }

  .about__me {
    width: 80%; /* Adjust image container size */
    margin: 0 auto; /* Center the image container */
    border-radius: 1rem;
    display: grid;
    place-items: center;
    padding: 1rem;
  }

  .about__me-image {
    border-radius: 1rem;
    overflow: hidden;
    transform: rotate(0);
    transition: var(--transition);
  }

  .about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }

  .about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
    transition: var(--transition);
  }

  .about__card:hover {
    border-color: var(--color-primary-variant);
    cursor: default;
    box-shadow: 0 0 25px #8e8eff, 0 0 50px #c49cff8a;
  }

  .about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .about__card h5 {
    align-items: center;
    font-size: 0.95rem;
  }

  .about__card small {
    font-size: 0.7rem;
    color: var(--color-primary);
  }

  .about__content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
  }

  #about h2 {
    font-size: 3rem;
    margin-bottom: 2rem;
    transition: transform 0.3s ease-in-out;
  }

  #about.pop h2 {
    transform: scale(1.1);
  }

  /* ======================= MEDIA QUERIES(MEDIUM DEVICES) ======================= */
  @media screen and (max-width: 1024px) {
    .about__container {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
    .about__me {
      width: 50%;
      margin: 2rem auto 4rem;
    }
    .about__content p {
      margin: 1rem 0 1.5rem;
    }
  }

  /* MEDIA QUERIES(SMALL DEVICES) =======================  */
  @media screen and (max-width: 600px) {
    .about__me {
      width: 100%;
      margin: 0rem auto 3rem;
    }
    .about__cards {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
    .about__content {
      text-align: center;
    }
    .about__content p {
      margin: 1.5rem 0;
    }
  }
