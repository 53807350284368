/* Portfolio Section */
#portfolio {
    background-color: var(--color-bg); /* Match background color */
    padding: 2rem 0; /* Add padding to match the spacing */
}

/* Portfolio Container */
.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

/* Portfolio Items */
.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    transition: var(--transition);
    align-items: center;
    box-shadow: 0 0 25px #8e8eff, 0 0 50px #c49cff8a;
    max-width: 300px; /* Ensure the item is not too large */
    margin: auto; /* Center the item */
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    box-shadow: 0 0 25px #8e8eff, 0 0 50px #c49cff8a;
}

/* Portfolio Item Images */
.portfolio__item-projects-image {
    width: 250px;
    height: 250px;
    border-radius: 1rem;
    margin: auto;
}

.portfolio__item__image {
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 1rem;
}

.portfolio__item h3 {
    margin: 1rem 0 1.5rem;
    text-align: center;
}

.portfolio__item-cta {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

/* Media Queries (Medium Devices) */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }
}

