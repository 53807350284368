:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(255, 255, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --transition: all 600ms ease; /* Smoother transition */
  }

  body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    margin: 0;
    padding: 0;
    transition: var(--transition);
  }

  body.dark-mode {
    background: #0b0c10;
  }

  body.light-mode {
    background: #ffffff; /* White for light mode */
  }

  header {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(120deg, #282c34, #4db5ff);
    overflow: hidden;
    position: relative;
  }

  header.dark {
    background: linear-gradient(120deg, #0b0c10, #1f1f38);
  }

  header.light {
    background: linear-gradient(120deg, #ffffff, #f0f0f0);
  }

  .header__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 2rem;
  }

  .header__left, .header__right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header__left {
    display: flex;
    justify-content: center; /* Center the image horizontally */
    margin-top: -2rem; /* Move the image up a bit */
  }

  .header__right {
    text-align: left;
    margin-left: 2rem; /* Add some space between the image and the text */
  }

  .header__container h5 {
    font-size: 1.2rem;
    color: var(--color-primary-variant);
  }

  .header__container h1 {
    font-size: 3rem;
    margin: 1rem 0;
  }

  .i-title {
    height: 50px;
    overflow: hidden;
    margin-bottom: 2rem;
  }

  .i-title-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    animation: move 10s ease-in-out infinite;
  }

  .i-item {
    height: 50px;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--color-light);
  }

  @keyframes move {
    0%, 100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-50px);
    }
    50% {
      transform: translateY(-100px);
    }
    75% {
      transform: translateY(-150px);
    }
  }

  .header__socials {
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
    margin-top: 2rem;
  }

  .header__socials a {
    color: var(--color-white);
    font-size: 1.5rem;
    transition: var(--transition);
  }

  .header__socials a:hover {
    color: var(--color-primary);
  }

  .me {
    margin-top: 0; /* Remove the top margin */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .me img {
    width: 25rem; /* Adjust the size of the image */
    height: 25rem; /* Ensure the height is the same as the width for perfect centering */
    border-radius: 50%;
    transition: var(--transition);
    /* border: 5px solid var(--color-primary); Add border */
  }

  .me img:hover {
    transform: scale(1.1);
  }

  .toggle-mode {
    position: absolute;
    top: 2rem;
    right: 2rem;
    background: var(--color-primary);
    color: var(--color-bg);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: var(--transition);
    display: flex;
    align-items: center;
  }

  .toggle-mode:hover {
    background: var(--color-white);
    color: var(--color-bg);
  }

  @media screen and (max-width: 768px) {
    header {
      padding: 2rem;
    }

    .header__container {
      flex-direction: column;
      align-items: center;
    }

    .header__right {
      text-align: center;
      margin-top: 2rem;
    }

    .header__container h1 {
      font-size: 100rem;
    }

    .header__container h5 {
      font-size: 10rem;
    }

    .i-title-wrapper {
      animation: move 7s ease-in-out infinite;
    }

    .i-item {
      font-size: 1.2rem;
    }

    .me img {
      width: 15rem;
      height: 15rem; /* Ensure the height is the same as the width for perfect centering */
    }
  }
